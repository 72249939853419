var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("textarea", {
    directives: [
      { name: "model", rawName: "v-model", value: _vm.raw, expression: "raw" },
    ],
    staticClass: "input",
    attrs: {
      rows: _vm.rows,
      type: _vm.type,
      placeholder: _vm.label,
      maxlength: _vm.maxlength,
      readonly: _vm.readonly,
    },
    domProps: { value: _vm.raw },
    on: {
      input: [
        function ($event) {
          if ($event.target.composing) return
          _vm.raw = $event.target.value
        },
        _vm.handleInput,
      ],
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }